import axios from '../modules/marketplace/axios-marketplace';

const getFornecedoresParceiros = params => axios.get('/fornecedor/parceiros', {
  params,
});
const getVisualizacao = id => axios.get(`/fornecedor/${id}/visualizacao`);
const searchFornecedores = params => axios.get('/fornecedor/search', {
  params,
});

export default {
  getFornecedoresParceiros,
  getVisualizacao,
  searchFornecedores,
};
