<template>
  <v-card link
          :to="`/fornecedor/${fornecedor.id}`"
          class="rounded-card card-shadow card-hover d-flex flex-column align-center">
    <v-img v-if="fornecedor.pessoa.logo"
           class="responsive-image mt-5"
           width="190px"
           height="70px"
           :src="fotoPath"
           :alt="fornecedor.pessoa.nome"
    />
    <v-img
      v-else
      :src="defaultImage"
      width="70px"
      height="70px">
    </v-img>
    <div class="text-center caption mb-2">
      <router-link :to="`/fornecedor/${fornecedor.id}`">
        {{ fornecedor.pessoa.nome }}
      </router-link>
    </div>
  </v-card>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import FileUrlPathService from '@/api/file-url-path-service';
import defaultImage from '../../assets/imagem-produto.png';

export default {
  mixins: [gridMixin],
  props: {
    fornecedor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultImage,
    };
  },
  computed: {
    fotoPath() {
      return FileUrlPathService.getPath(this.fornecedor.pessoa.logo.path);
    },
  },
};
</script>
